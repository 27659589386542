export default {
    INDEX: '',
    QUIZ: 'quiz',
    RESULT: 'result',
    RESULTNEW: 'resultNew',
    BILLING: 'billing',
    SIGNUP: 'signup',
    CONGRATS: 'congrats',
    LOGIN: 'login',
    UNSUBSCRIBE: 'unsubscribe',
    OFFER: 'offer',
    FORGOT: 'forgot',
    RESET: 'reset'
};

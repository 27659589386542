import Modal from 'modal-vanilla';

export const setScrollTop = () => {
    window.scrollTop = 0;
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
};

export const templateRenderer = templateId => {
    const page = document.querySelector('.main');
    const template = document.getElementById(templateId).content;
    let wrapper;

    const mount = () => {
        // page.insertBefore(wrapper, page.firstChild);
        wrapper = document.createElement('div');
        wrapper.append(template.cloneNode(true));
        page.prepend(wrapper);
    };

    const unmount = () => {
        wrapper.remove();
        wrapper = null;
    };
    return { mount, unmount };
};

export const templateModal = templateId => {
    const template = document.getElementById(templateId).content;
    const wrapper = document.createElement('div');
    wrapper.appendChild(template.cloneNode(true));
    const modal = new Modal({
        content: wrapper,
        keyboard: false,
        transition: false,
        headerClose: false,
        footer: false,
        construct: false,
        backdrop: false
    });

    return modal;
};

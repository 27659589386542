/* eslint-disable standard/no-callback-literal */
import localforage from 'localforage';
import axios from 'axios';
import _curry from 'lodash/curry';
import _filter from 'lodash/filter';
import jwtDecode from 'jwt-decode';

export default class authService {
    constructor({
        authTokenKey = 'auth_token',
        refreshTokenKey = 'refresh_token',
        apiKey,
        baseURL
    }) {
        this.authTokenKey = authTokenKey;
        this.refreshTokenKey = refreshTokenKey;
        this.authToken = null;
        this.refreshToken = null;
        this.baseURL = baseURL;
        this.apiKey = apiKey;
        this.subscriptions = null;
        this.ready = false;
    }

    init(cb) {
        localforage.setDriver(localforage.LOCALSTORAGE);
        if (!this.ready) {
            this.invoke(result => {
                const [authToken, refreshToken] = result;

                this.authToken = authToken;
                this.refreshToken = refreshToken;

                cb(result);
            });
        } else {
            // #TODO Drop error
            cb([this.authToken, this.refreshToken]);
        }
    }

    store({ authToken, refreshToken }, cb) {
        Promise.all([
            localforage.setItem(this.authTokenKey, authToken),
            localforage.setItem(this.refreshTokenKey, refreshToken)
        ])
            .then(_ => {
                this.authToken = authToken;
                this.refreshToken = refreshToken;
                if (cb) {
                    cb({ authToken, refreshToken });
                }
            });
    }

    drop(cb) {
        Promise.all([
            localforage.removeItem(this.tokenKey),
            localforage.removeItem(this.refreshKey)
        ])
            .then(cb)
            .catch(_ => {
                // #TODO Error
            });
    }

    invoke(cb) {
        const { authTokenKey, refreshTokenKey } = this;
        Promise.all([
            localforage.getItem(authTokenKey),
            localforage.getItem(refreshTokenKey)
        ])
            .then(cb)
            .catch();
    }

    register({ email = 'test@gmail.com', password = '1234' }, cb) {
        axios({
            method: 'POST',
            url: `${this.baseURL}/api/register`,
            headers: {
                'X-API-KEY': this.apiKey
            },
            data: {
                email,
                password
            }
        })
            .then(_curry(cb)(null))
            .catch(cb);
    }

    login({ username, password }, cb) {
        axios({
            method: 'POST',
            url: `${this.baseURL}/api/login_check`,
            headers: {
                'X-API-KEY': this.apiKey
            },
            data: {
                username,
                password
            }
        }).then(({ data }) => {
            this.store(
                {
                    authToken: data.token,
                    refreshToken: data.refresh_token
                }, cb
            );
        }).catch(cb);
    }

    refresh(cb) {
        axios({
            method: 'POST',
            url: `${this.baseURL}/api/token/refresh`,
            headers: {
                'X-API-KEY': this.apiKey
            },
            data: {
                refresh_token: this.refreshToken
            }
        }).then(cb);
    }

    getClientAuthorization(cb) {
        axios({
            method: 'GET',
            url: `${this.baseURL}/api/payment/profile`,
            headers: {
                Authorization: `Bearer ${this.authToken}`,
                'X-API-KEY': this.apiKey
            }
        }).then(cb);
    }

    getPaymentPlans(cb) {
        axios({
            method: 'GET',
            url: `${this.baseURL}/api/payment/plans`,
            headers: {
                Authorization: `Bearer ${this.authToken}`,
                'X-API-KEY': this.apiKey
            }
        })
            .catch(_ => {
                cb(_);
            })
            .then(data => cb(null, data));
    }

    paymentCheckout({
        nonce,
        planId
    }, cb) {
        axios({
            method: 'POST',
            url: `${this.baseURL}/api/payment/checkout`,
            headers: {
                Authorization: `Bearer ${this.authToken}`,
                'X-API-KEY': this.apiKey
            },
            data: {
                nonce,
                plan_id: planId
            }
        })
            .then(response => { cb(response); })
            .catch(cb);
    }

    getSubscriptions(cb) {
        axios({
            method: 'GET',
            url: `${this.baseURL}/api/payment/subscription`,
            headers: {
                Authorization: `Bearer ${this.authToken}`,
                'X-API-KEY': this.apiKey
            }
        }).then(response => {
            this.subscriptions = response.data.data;
            cb(response);
        }).catch(response => { cb(response); });
    }

    getSubscriptionsLength() {
        return this.subscriptions && this.subscriptions;
    }

    deleteSubscription(id, cb) {
        axios({
            method: 'DELETE',
            url: `${this.baseURL}/api/payment/subscription/${id}`,
            headers: {
                Authorization: `Bearer ${this.authToken}`,
                'X-API-KEY': this.apiKey
            }
        }).then(response => {
            this.subscriptions = _filter(this.subscriptions, { id });
            cb(response);
        });
    }

    forgotPassword(email, cb) {
        axios({
            method: 'POST',
            url: `${this.baseURL}/api/forgot/password`,
            headers: {
                'X-API-KEY': this.apiKey
            },
            data: {
                email: email
            }
        }).then(cb);
    }

    resetPassword({
        password,
        token
    }, cb) {
        this.invoke((data) => {
            axios({
                method: 'POST',
                url: `${this.baseURL}/api/reset/password`,
                headers: {
                    'X-API-KEY': this.apiKey
                },
                data: {
                    token,
                    password: password
                }
            }).then(cb);
        });
    }

    getUser() {
        const token = jwtDecode(this.authToken);
        const { user } = token;
        return user;
    }

    getRegion(cb) {
        axios({
            method: 'GET',
            url: `${this.baseURL}/api/consent/region`,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            cb(response.data);
        });
    }

    sendEmail({ email, data_collection_consent }, cb) { // eslint-disable-line camelcase
        axios({
            method: 'POST',
            url: `${this.baseURL}/api/consent/email`,
            headers: {
                'X-API-KEY': this.apiKey
            },
            data: {
                email,
                data_collection_consent
            }
        }).then(cb);
    }

    loginAuth({ type, id_token, access_token }, cb) { // eslint-disable-line camelcase
        axios({
            method: 'POST',
            url: `${this.baseURL}/api/login_check_oauth`,
            headers: {
                'X-API-KEY': this.apiKey
            },
            data: {
                type,
                id_token,
                access_token
            }
        }).then(({ data }) => {
            this.store(
                {
                    authToken: data.token,
                    refreshToken: data.refresh_token
                }, cb
            );
        }).catch(cb);
    }
};

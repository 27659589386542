import Router from './createRouter';
import * as Localize from '../utils/localize';
import * as SmartBanner from 'smart-app-banner/dist/smart-app-banner.js';
import { getData } from '../utils';

const hideMenu = () => {
    document.getElementById('navClose').classList.remove('nav_close');
    document.getElementById('navClose').classList.add('nav_open');
    document.getElementById('navMob').classList.remove('nav__mob__opened');
    [].forEach.call(document.querySelectorAll('.nav__mob .nav__mob-item'), function(el) {
        el.style.display = 'none';
    });
    document.querySelector('.nav_item_btn__mob').style.display = 'none';
    window.onscroll = function() { };
};

const showMenu = () => {
    if (document.getElementById('navClose').classList.contains('nav_close')) {
        hideMenu();
    } else if (document.getElementById('navClose').classList.contains('nav_open')) {
        document.getElementById('navClose').classList.remove('nav_open');
        document.getElementById('navClose').classList.add('nav_close');
        [].forEach.call(document.querySelectorAll('.nav__mob .nav__mob-item'), function(el) {
            el.style.display = 'inline-block';
        });
        document.querySelector('.nav_item_btn__mob').style.display = 'flex';
        document.getElementById('navMob').classList.add('nav__mob__opened');
        window.onscroll = function() {
            window.scrollTo(0, 0);
        };
    }
};

const initBgAnimation = () => {
    if (window.screen.width <= 960) {
        bgAnimationMob();
    } else {
        bgAnimationDesc();
    }
};

const bgAnimationDesc = () => {
    document.querySelector('.fasting-app').addEventListener('mouseenter', () => {
        document.querySelector('.fasting-app').classList.add('mouseenter');
        document.querySelector('.fasting-app').classList.remove('mouseleave');
    });
    document.querySelector('.fasting-app').addEventListener('mouseleave', () => {
        document.querySelector('.fasting-app').classList.remove('mouseenter');
        document.querySelector('.fasting-app').classList.add('mouseleave');
    });
    document.querySelector('.fasting-tracker').addEventListener('mouseenter', () => {
        document.querySelector('.fasting-tracker_img').style.display = 'none';
        document.querySelector('.fasting-tracker_gif').style.display = 'block';
        setTimeout(() => {
            document.querySelector('.fasting-tracker_img').style.display = 'block';
            document.querySelector('.fasting-tracker_gif').style.display = 'none';
        }
        , 8000);
    });
    document.querySelector('.fasting-tracker').addEventListener('mouseleave', () => {
        document.querySelector('.fasting-tracker_img').style.display = 'block';
        document.querySelector('.fasting-tracker_gif').style.display = 'none';
    });
    document.querySelector('.water-tracker').addEventListener('mouseenter', () => {
        document.querySelector('.water-tracker_gif_static').style.display = 'none';
        document.querySelector('.water-tracker_gif').style.display = 'block';
        setTimeout(() => {
            document.querySelector('.water-tracker_gif_static').style.display = 'block';
            document.querySelector('.water-tracker_gif').style.display = 'none';
        }
        , 4000);
        document.querySelector('.water-tracker').classList.add('mouseenter');
        document.querySelector('.water-tracker').classList.remove('mouseleave');
    });
    document.querySelector('.water-tracker').addEventListener('mouseleave', () => {
        document.querySelector('.water-tracker').classList.add('mouseleave');
        document.querySelector('.water-tracker').classList.remove('mouseenter');
    });
    document.querySelector('.meal-plan').addEventListener('mouseenter', () => {
        document.querySelector('.meal-plan').classList.add('mouseenter');
        document.querySelector('.meal-plan').classList.remove('mouseleave');
    });
    document.querySelector('.meal-plan').addEventListener('mouseleave', () => {
        document.querySelector('.meal-plan').classList.add('mouseleave');
        document.querySelector('.meal-plan').classList.remove('mouseenter');
    });
    document.querySelector('.enjoy-features').addEventListener('mouseenter', () => {
        document.querySelector('.enjoy-features').classList.add('mouseenter');
        document.querySelector('.enjoy-features').classList.remove('mouseleave');
    });
    document.querySelector('.enjoy-features').addEventListener('mouseleave', () => {
        document.querySelector('.enjoy-features').classList.add('mouseleave');
        document.querySelector('.enjoy-features').classList.remove('mouseenter');
    });
};

const bgAnimationMob = () => {
    document.addEventListener('scroll', () => {
        const windowOffset = window.pageYOffset;
        const startFastingApp = document.querySelector('.fasting-app').offsetTop;
        const finishFastingApp = (startFastingApp + document.querySelector('.fasting-app').offsetHeight);
        if (windowOffset > startFastingApp && windowOffset < finishFastingApp) {
            document.querySelector('.fasting-app').classList.add('scrollTo');
        } else {
            document.querySelector('.fasting-app').classList.remove('scrollTo');
        };
        const startFastingTracker = document.querySelector('.fasting-tracker').offsetTop;
        const finishFastingTracker = (startFastingTracker + document.querySelector('.fasting-tracker').offsetHeight);
        if (windowOffset > startFastingTracker && windowOffset < finishFastingTracker) {
            document.querySelector('.fasting-tracker_img').style.display = 'none';
            document.querySelector('.fasting-tracker_gif').style.display = 'block';
            setTimeout(() => {
                document.querySelector('.fasting-tracker_img').style.display = 'block';
                document.querySelector('.fasting-tracker_gif').style.display = 'none';
            }
            , 8000);
        } else {
            document.querySelector('.fasting-tracker_img').style.display = 'block';
            document.querySelector('.fasting-tracker_gif').style.display = 'none';
        }
        const startWaterTracker = document.querySelector('.water-tracker').offsetTop;
        const finishWaterTracker = (startWaterTracker + document.querySelector('.water-tracker').offsetHeight);
        if (windowOffset > startWaterTracker && windowOffset < finishWaterTracker) {
            document.querySelector('.water-tracker').classList.add('scrollTo');
            document.querySelector('.water-tracker_gif_static').style.display = 'none';
            document.querySelector('.water-tracker_gif').style.display = 'block';
            setTimeout(() => {
                document.querySelector('.water-tracker_gif_static').style.display = 'block';
                document.querySelector('.water-tracker_gif').style.display = 'none';
            }
            , 4000);
        } else {
            document.querySelector('.water-tracker').classList.remove('scrollTo');
            document.querySelector('.water-tracker_gif_static').style.display = 'block';
            document.querySelector('.water-tracker_gif').style.display = 'none';
        };
        const startMealPlan = document.querySelector('.meal-plan').offsetTop;
        const finishMealPlan = (startMealPlan + document.querySelector('.meal-plan').offsetHeight);
        if (windowOffset > startMealPlan) {
            document.querySelector('.meal-plan_img__mob').src = 'assets/images/newmain_phone5_on_mob.png';
        } else if (windowOffset < startMealPlan || windowOffset > finishMealPlan) {
            document.querySelector('.meal-plan_img__mob').src = 'assets/images/newmain_phone5_off_mob.png';
        };
        const startEnjoyFeatures = document.querySelector('.enjoy-features').offsetTop;
        const finishEnjoyFeatures = (startEnjoyFeatures + document.querySelector('.enjoy-features').offsetHeight);
        if (windowOffset > startEnjoyFeatures) {
            document.querySelector('.enjoy-features').classList.add('scrollTo');
        } else if (windowOffset < startEnjoyFeatures || windowOffset > finishEnjoyFeatures) {
            document.querySelector('.enjoy-features').classList.remove('scrollTo');
        };
    });
};

export default path => ({
    [`${path}`]: {
        uses: () => {
            gtag('event', 'Main page opened'); // eslint-disable-line no-undef

            new SmartBanner({ // eslint-disable-line no-new
                daysHidden: 15,
                daysReminder: 90,
                appStoreLanguage: 'us',
                title: 'Window - Fasting tracker',
                author: 'Thriveport',
                button: 'View'
            });
            const observer = new MutationObserver(mutationRecords => window.scrollTo(0, 0)); // eslint-disable-line no-undef
            observer.observe(document.body, {
                childList: true
            });
            getData('data', result => {
                const lengthObj = result[0] ? Object.keys(result[0]).length : null;
                const header = document.querySelectorAll('.nav_item');
                if (lengthObj < 4) {
                    header.forEach(item => {
                        if (!item.classList.contains('try-for-free')) {
                            item.classList.add('hidden');
                        }
                    });
                } else {
                    header.forEach(item => {
                        if (!item.classList.contains('try-for-free')) {
                            item.classList.remove('hidden');
                        }
                    });
                }
            });
            Router.updatePageLinks();
            Localize({
                path: '/assets/translations/',
                initLoc: true
            });
            document.getElementById('navClose').addEventListener('click', showMenu);

            // quiz banner
            document.addEventListener('scroll', () => {
                const quizBnt = document.querySelector('.quiz-fixed');
                const windowOffset = window.pageYOffset;
                const posShow = document.querySelector('.fasting-app_feedback').offsetTop;
                const posHide = (document.querySelector('.enjoy-features').offsetTop + 300);
                if (windowOffset > posShow && windowOffset < posHide) {
                    quizBnt.style.display = 'block';
                } else {
                    quizBnt.style.display = 'none';
                }
            });
            // faq navigation desktop
            [].forEach.call(document.querySelectorAll('.faq-question'), function(el) {
                el.addEventListener('click', () => {
                    [].forEach.call(document.querySelectorAll('.faq-question'), function(el) {
                        el.classList.remove('current');
                    });
                    el.classList.add('current');
                    const faqNumb = el.classList[1];
                    [].forEach.call(document.querySelectorAll('.faq-answer'), function(el) {
                        if (el.classList.contains(faqNumb)) {
                            el.style.display = 'block';
                        } else {
                            el.style.display = 'none';
                        }
                    });
                });
            });
            // faq navigation mobile
            [].forEach.call(document.querySelectorAll('.faq_mob'), function(el) {
                el.addEventListener('click', () => {
                    el.classList.toggle('active');
                });
            });
            initBgAnimation();
            window.addEventListener('resize', () => {
                initBgAnimation();
            });
        },
        hooks: {
            before: done => {
                const page = document.querySelector('.home-main');
                page.removeAttribute('hidden');
                done();

                hideMenu();
            },
            leave: _ => {
                document.getElementById('navClose').removeEventListener('click', showMenu);
                const page = document.querySelector('.home-main');
                page.setAttribute('hidden', true);
                hideMenu();
            }
        }
    }
});

/* eslint-disable standard/no-callback-literal */
import localforage from 'localforage';

export const objdata = {};

export const setObj = (key, value) => {
    objdata[key] = value;
};

localforage.setDriver(localforage.LOCALSTORAGE);

export const setData = (data, cb) => {
    Promise.all([
        localforage.setItem('data', data)
    ]).then(cb);
};

export const getData = (key, cb) => {
    Promise.all([
        localforage.getItem(key)
    ]).then(cb);
};

import { templateRenderer, setScrollTop } from '../utils';
import * as Localize from '../utils/localize';

export default path => {
    const congratsTemplate = templateRenderer('form_congrats');

    return {
        [path]: {
            uses: _ => {
                setScrollTop();
                Localize({
                    path: '/assets/translations/',
                    initLoc: true
                });
            },
            hooks: {
                before: done => {
                    congratsTemplate.mount();
                    done();
                },
                leave: _ => {
                    congratsTemplate.unmount();
                }
            }
        }
    };
};

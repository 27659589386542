import Router from './createRouter';
import queryString from 'query-string';
import ROUTES from './constants';
import serialize from 'form-serialize';
import authService from '../auth';
import { templateRenderer, setScrollTop } from '../utils';
import * as Localize from '../utils/localize';

const validateCreateAccForm = (e, cb) => {
    const inp = e.currentTarget;
    const elParent = inp.parentElement;
    let valid = true;
    if (elParent.childNodes[1].validity.valid) {
        gtag('event', 'New password entered'); // eslint-disable-line no-undef
    }
    if (elParent.childNodes[3].validity.valid) {
        gtag('event', 'New password confirmed'); // eslint-disable-line no-undef
    }
    for (let i = 0; i < elParent.childNodes.length; i++) {
        if (elParent.childNodes[i].tagName === 'INPUT') {
            if (!elParent.childNodes[i].validity.valid) {
                elParent.childNodes[i].classList.add('notvalid');
                valid = false;
            } else {
                elParent.childNodes[i].classList.remove('notvalid');
                if (elParent.childNodes[1].value !== elParent.childNodes[3].value) {
                    elParent.childNodes[3].classList.add('notvalid');
                    valid = false;
                } else {
                    elParent.childNodes[3].classList.remove('notvalid');
                }
            }
        }
    }
    const submit = document.querySelector('.form-submit');
    if (valid) {
        submit.innerHTML = 'Reset';
        submit.classList.add('active');
        submit.addEventListener('click', cb);
    } else {
        submit.removeEventListener('click', cb);
        submit.innerHTML = 'Fill the form';
        submit.classList.remove('active');
    }
};
const resetTemplate = templateRenderer('form_reset');
export default path => ({
    [path]: {
        uses: (params, query) => {
            setScrollTop();
            Localize({
                path: '/assets/translations/',
                initLoc: true
            });
            const { token } = queryString.parse(query);
            if (!token) {
                Router.navigate(ROUTES.INDEX);
            }
            const inputs = document.querySelectorAll('#form_reset_body input');
            const form = document.getElementById('form_res');

            const submitCallback = event => {
                const data = serialize(form, {
                    hash: true
                });

                if (token) {
                    authService.resetPassword({
                        password: data.newPassword,
                        token
                    }, _ => {
                        gtag('event', 'Password updated'); // eslint-disable-line no-undef
                        Router.navigate(ROUTES.INDEX);
                    });
                }

                event.preventDefault();
            };
            inputs.forEach(item => {
                item.addEventListener('input', (e) => validateCreateAccForm(e, submitCallback));
            });
        },
        hooks: {
            before: done => {
                resetTemplate.mount();
                done();
            },
            leave: _ => {
                resetTemplate.unmount();
            }
        }
    }
});

import 'smart-app-banner/dist/smart-app-banner.css';
import authService from './auth';
import {
    ROUTES,
    IndexRoute,
    CongratsRoute,
    ForgotRoute,
    ResetRoute,
    Router
} from './router';

const IMAGES_PATH = '/assets/images';

// Preload image assets preventing
// image flickering on page transitions
(_ => {
    const preloadImgs = [];
    const images = [];

    for (var i = 0; i < preloadImgs.length; i++) {
        images[i] = new Image(); // eslint-disable-line no-undef
        images[i].src = `${IMAGES_PATH}/${preloadImgs[i]}`;
    }
})();

/*
Keep in mind that order of routes is matter
*/
Router.on({
    ...CongratsRoute(ROUTES.CONGRATS),
    ...ForgotRoute(ROUTES.FORGOT),
    ...ResetRoute(ROUTES.RESET),
    ...IndexRoute(ROUTES.INDEX)
});

Router.notFound(_ => {
    Router.navigate(ROUTES.INDEX);
});

// ENTRY POINT
const resolveApp = () => {
    authService.init(_ => {
        Router.resolve();
    });
};

document.onload = resolveApp();

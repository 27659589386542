import serialize from 'form-serialize';
import { templateModal, templateRenderer, setScrollTop } from '../utils';
import authService from '../auth';
import ROUTES from './constants';
import Router from './createRouter';
import * as Localize from '../utils/localize';

const validateCreateAccForm = (e, cb) => {
    const inp = e.currentTarget;
    const elParent = inp.parentElement;
    let valid = true;
    for (let i = 0; i < elParent.childNodes.length; i++) {
        if (elParent.childNodes[i].tagName === 'INPUT') {
            if (!elParent.childNodes[i].validity.valid) {
                elParent.childNodes[i].classList.add('notvalid');
                valid = false;
            } else {
                gtag('event', 'Forgot password email entered'); // eslint-disable-line no-undef
                elParent.childNodes[i].classList.remove('notvalid');
            }
        }
    }

    const submit = document.querySelector('.form-submit');

    if (valid) {
        submit.classList.add('active');
        submit.addEventListener('click', cb);
    } else {
        submit.removeEventListener('click', cb);
        submit.classList.remove('active');
    }
};

export default path => {
    const formForgotTemplate = templateRenderer('form_forgot');
    const popupDoneInfoModal = templateModal('popup_done');
    popupDoneInfoModal.on('show', _ => {
        setTimeout(() => {
            const btnOk = document.querySelector('#popup_done_body .popup-box-btn__btn');
            Localize({
                path: '/assets/translations/',
                initLoc: true
            });
            btnOk.addEventListener('click', () => {
                popupDoneInfoModal.hide();
                Router.navigate(ROUTES.INDEX);
            }, {
                once: true
            });
        }, 0);
    });
    return {
        [path]: {
            uses: _ => {
                setScrollTop();
                Localize({
                    path: '/assets/translations/',
                    initLoc: true
                });
                const inputs = document.querySelectorAll('#form_forgot_body input');
                const form = document.getElementById('form_forgot');

                const submitCallback = event => {
                    event.preventDefault();
                    const data = serialize(form, { hash: true });
                    authService.forgotPassword(data.email, _ => {
                        popupDoneInfoModal.show();
                    });
                };
                inputs.forEach(item => {
                    item.addEventListener('input', (e) => validateCreateAccForm(e, submitCallback));
                });
            },
            hooks: {
                before: done => {
                    formForgotTemplate.mount();
                    done();
                },
                leave: _ => {
                    gtag('event', 'Forgot password screen passed'); // eslint-disable-line no-undef
                    formForgotTemplate.unmount();
                }
            }
        }
    };
};
